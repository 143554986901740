import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ComponentType, Suspense, lazy } from 'react';
import { Toaster } from 'react-hot-toast';

import './styles/index.scss';
import './styles/index.css';

import EmailVerificationPage from '@pages/EmailVerification';
import GeneralSettings from '@pages/Settings/General';
import HomeLayout from '@pages/layouts/HomeLayaout';
import MembersSettings from '@pages/Settings/Members';
import PrivateLayout from '@pages/layouts/PrivateLayout';
import { Provider } from 'react-redux';
import ResetPasswordPage from '@pages/ResetPassword';
import SingUpPage from '@pages/SignUp';
import UpdatePasswordPage from '@pages/UpdatePassword';
import WelcomLayaout from '@pages/layouts/WelcomeLayout';
import { Join } from '@pages/Join';
import { store } from './redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import NoMatch from '@pages/Errors/NoMatch';
import ErrorBoundary from '@pages/Errors';
import ServerError from '@pages/Errors/ServerError';
import SettingsLayout from '@pages/layouts/SettingsLayout';
import AccountSettings from '@pages/Settings/Account';
import ChannelSettings from '@widgets/components/Modals/ChannelSettings/ChannelSettings.component';
import { ShareDoc } from '@widgets/components/ShareDoc';
import DeleteConfirmation from '@widgets/components/Modals/DeleteConfirmation/DeleteConfirmation.component';
import AuthLayaout from '@pages/layouts/AuthLayout/AuthLayout.component';
import Login from '@pages/Login/Login.component';
import WhiteboardSettings from '@widgets/components/Modals/WhitboardSettings';
import TrashPage from '@pages/Home/Trash/TrashPage.component';
import Loading from '@shared/common/Loading';

export const lazyMinLoadTime = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  minLoadTimeMs = 2000
) =>
  lazy(() =>
    Promise.all([
      factory(),
      new Promise((resolve) => setTimeout(resolve, minLoadTimeMs)),
    ]).then(([moduleExports]) => moduleExports)
  );

const WelcomePage = lazyMinLoadTime(
  () => import('@pages/Welcome/Welcome'),
  1
);
const Home = lazyMinLoadTime(() => import('@pages/Home'), 1);
const Workspace = lazyMinLoadTime(() => import('@pages/Home/workspace/Workspace'), 1);


const Routing = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<PrivateLayout />}>
          <Route element={<HomeLayout />}>
            <Route path='/' element={<Navigate replace to='/home' />} />
            <Route path='/home' element={<Home />} />
            <Route path='/workspace/:documentId' element={<Workspace />} />
            <Route path='/trash' element={<TrashPage />} />
          </Route>
          <Route element={<SettingsLayout />}>
            <Route
              path='/settings'
              element={<Navigate replace to='/settings/account' />}
            />
            <Route path='/settings/account' element={<AccountSettings />} />
            <Route path='/settings/general' element={<GeneralSettings />} />
            <Route path='/settings/members' element={<MembersSettings />} />
            {/* <Route path='/settings/current-plan' element={<CurrentPlan/>} />
            <Route path='/settings/upgrade' element={<Upgrade/>} />
            <Route path='/settings/billing-details' element={<BillingDetails/>} />  */}
          </Route>
          <Route path='/unit/:unitId' element={<Join />} />
        </Route>

        <Route element={<WelcomLayaout />}>
          <Route path='/welcome' element={<WelcomePage />} />
        </Route>

        <Route element={<AuthLayaout />}>
          <Route path='/login' element={<Login />} />
          <Route path='/sign-up' element={<SingUpPage />} />
          <Route
            path='/email-verification'
            element={<EmailVerificationPage />}
          />
          <Route path='/forgot-password' element={<ResetPasswordPage />} />
          <Route path='/reset-password' element={<UpdatePasswordPage />} />
        </Route>

        <Route path='*' element={<NoMatch />} />
        <Route path='/404' element={<NoMatch />} />
        <Route path='/500' element={<ServerError />} />
      </Routes>
    </Suspense>
  );
};

function App() {
  return (
    <ErrorBoundary action={() => (window.location.href = '/home')}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}
      >
        <Provider store={store}>
          <BrowserRouter>
            <Routing />

            <ChannelSettings />
            <ShareDoc />
            <DeleteConfirmation />
            <WhiteboardSettings />
          </BrowserRouter>
        </Provider>
      </GoogleOAuthProvider>
      <Toaster />
    </ErrorBoundary>
  );
}

export default App;
