import { useEffect, useState } from 'react';
import Button from '@shared/common/Button';
import { ReactComponent as Plus } from '@images/plus.svg';
import { MemberItem } from './MemberItem';
import { GuestItem } from './GuestItem';
import MemberInvite from './MemberInvite';
import {
  getWorkspaceMembers,
  getWorkspaceGuests,
} from '@app/services/workspace.service';
import { WorkspaceMember } from '@entities/models/workspace';
import toast from 'react-hot-toast';
import { ErrorToast, SuccessToast } from '@shared/common/Toast';
import { track } from '@amplitude/analytics-browser';
import { changeUserWorkpsaceRole, transferWorkpsaceOwnership, deleteUserFromWorkspace } from '@app/services/workspace.service';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@app/redux/features/userSlice';

type Tabs = 'members' | 'guests';

const MembersSettings = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [members, setMembers] = useState<any[]>([]);
  const [guests, setGuests] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<Tabs>('members');
  const currentUser = useSelector(selectCurrentUser);

  async function requestMembers() {
    if (!currentUser.workspace) return;

    const getMembersPromise = getWorkspaceMembers(currentUser.workspace.id)
      .catch(err => {
        console.error('Error when requesting workspace members', err);
        return [];
      });

    const getGuestsPromise = getWorkspaceGuests(currentUser.workspace.id)
      .catch(err => {
        console.error('Error when requesting workspace members', err);
        return [];
      });

    const [members, guests] = await Promise.all([getMembersPromise, getGuestsPromise]);

    const membersToShow = (members || []).filter((el) => !el.user.deleted) as WorkspaceMember[];

    setMembers(membersToShow);
    setGuests(guests ?? []);
  }

  useEffect(() => {
    track('members_settings_opened');
    if (!!currentUser.workspace?.id) {
      requestMembers();
    }
  }, []);

  if (!currentUser.workspace?.id) {
    console.error(
      'Workspace Id is not derined. Unable to retrieve workspace members. Try to log out, cleanup cookies and local storage, and log in again.'
    );
  }

  const createTransferOwnershipCb = (futureOwnerId: string) => async () => {
    if (!currentUser.workspace) {
      return;
    }

    try {
      await transferWorkpsaceOwnership({
        workspaceId: currentUser.workspace?.id,
        userId: futureOwnerId,
      });

      requestMembers()

      toast.custom(<SuccessToast text='Access level successfully changed!' />);
    } catch (e) {
      toast.custom(<ErrorToast text='Unable change access level' />, {
        style: { background: '#FFECEE' },
      });
    }
  };

  const createGuestToMemberCb = (guestId: string) => async () => {
    if (!currentUser.workspace) {
      return;
    }

    try {
      await changeUserWorkpsaceRole({
        workspaceId: currentUser.workspace?.id,
        userId: guestId,
        role: 'member',
      });

      requestMembers();

      toast.custom(<SuccessToast text='Access level successfully changed!' />);
    } catch (e) {
      toast.custom(<ErrorToast text='Unable change access level' />, {
        style: { background: '#FFECEE' },
      });
    }
  };

  const handleMemberDelete = async (id: string) => {
    const listToSearchItem = activeTab === 'members' ? members : guests;

    const itemToDelete = listToSearchItem.find((x) => x.user.id === id);

    if (!itemToDelete || !currentUser.workspace) {
      return;
    }

    try {
      deleteUserFromWorkspace(currentUser.workspace.id, id);
      const susccessLabel = itemToDelete?.user?.name ? `The user '${itemToDelete?.user?.name} (${itemToDelete.user.email})' has been deleted` : `The user '${itemToDelete.user.email}' has been deleted`;

      toast(<SuccessToast text={susccessLabel}/>);
      setMembers((members) => members.filter((x) => x.user.id !== id));
    } catch (error) {
      const errorLabel = itemToDelete?.user?.name ? `The user '${itemToDelete.user.name} (${itemToDelete.user.email})' has not been deleted` : `The user '${itemToDelete.user.email}' has not been deleted`;
      toast(<ErrorToast text={errorLabel} />, { style: { background: '#FFECEE' } });
    }
  };

  return (
    <>
      <MemberInvite modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <div className='flex justify-between items-center'>
        <ul className='flex'>
          <li
            className={`text-2xl3 ${
              activeTab === 'members' ? 'text-text90' : 'text-text50'
            } mr-10 relative flex items-center`}
          >
            <button onClick={() => setActiveTab('members')}>
              Members ({members.length})
            </button>
          </li>
          <li
            className={`text-2xl3 ${
              activeTab === 'guests' ? 'text-text90' : 'text-text50'
            } mr-10 relative flex items-center`}
          >
            <button onClick={() => setActiveTab('guests')}>
              Guests ({guests.length})
            </button>
          </li>
        </ul>
        <Button
          label='Invite'
          styleType='small-primary'
          onClick={() => {
            track('members_settings_invite_action');
            setModalIsOpen(true);
          }}
          className='pl-3'
          icon={<Plus className='mr-1.5 [&>path]:stroke-white' />}
        />
      </div>
      <div className='mt-9 border-t border-text10'>
        {activeTab === 'members' &&
          members.map((item) => (
            <MemberItem
              id={item.user.id}
              key={item.user.email}
              name={item.user.name}
              email={item.user.email}
              role={item.role === 'owner' ? 'owner' : 'member'}
              workspaceId={currentUser.workspace?.id}
              handleChange={createTransferOwnershipCb(item.user.id)}
              handleDelete={handleMemberDelete}
            />
          ))}
        {activeTab === 'guests' &&
          guests.map((item) => (
            <GuestItem
              id={item.user.id}
              key={item.user.email}
              name={item.user.name}
              email={item.user.email}
              workspaceId={currentUser.workspace?.id}
              handleChange={createGuestToMemberCb(item.user.id)}
              handleDelete={handleMemberDelete}
            />
          ))}
      </div>
    </>
  );
};

export default MembersSettings;
