import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  readOnly: false,
  update: 0,
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setReadOnly(state, action) {
      state.readOnly = action.payload;
    },
    setUpdate(state, action) {
      state.update = state.update + action.payload;
    },
  },
});

export const { setReadOnly, setUpdate } = commentsSlice.actions;
export default commentsSlice.reducer;
