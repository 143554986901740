import { useClickOutside } from '@app/hooks/useClickOutside';

import { ReactComponent as Logo } from '@images/logo.svg';
import { ReactComponent as Hide } from '@images/doubleArrow.svg';

import styles from './style.module.scss';
import useWorkspace from '@app/hooks/useWorkspace';
import SettingsMenu from './settings-menu';
import { useLocalStorage } from 'usehooks-ts';
import Tippy from '@tippyjs/react';

const SidebarHeader = () => {
  const { workspace } = useWorkspace();
  const [, hideSidebar] = useLocalStorage('sidebarHidden', false);

  const settingsMenuClick = useClickOutside(false);
  return (
    <div ref={settingsMenuClick.ref} className={styles.sidebarHeader}>
      <div
        className='row'
        onClick={() =>
          settingsMenuClick.setIsVisible(!settingsMenuClick.isVisible)
        }
      >
        <Logo className={styles.sidebarHeaderLogo} />
        <div className={styles.sidebarHeaderWorkspaceName}>
          {workspace?.name}
        </div>
      </div>

      <Tippy
        duration={0}
        content='Hide sidebar'
        className='!p-2 text-text5 !bg-text90 !opacity-100 !rounded !text-xs'
      >
        <div
          className={styles.sidebarHeaderHideIcon}
          onClick={() => hideSidebar(true)}
        >
          <Hide />
        </div>
      </Tippy>
      {settingsMenuClick.isVisible && (
        <SettingsMenu
          closeCallback={() => {
            settingsMenuClick.setIsVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default SidebarHeader;
