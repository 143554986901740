import ChannelSettingsButton from '@widgets/components/Actions/ChannelSettingsButton/ChannelSettingsButton.component';
import { UnitActionListProps } from '../props';
import CopyLinkButton from '@widgets/components/Actions/CopyLinkButton/CopyLinkButton.component';
import ShareButton from '@widgets/components/Actions/ShareButton/ShareButton.component';
import DeleteButton from '@widgets/components/Actions/DeleteButton/DeleteButton.component';

const ChannelActionList = ({ unit, callback }: UnitActionListProps) => {
  return (
    <>
      <ChannelSettingsButton unit={unit} callback={callback} />
      <CopyLinkButton unit={unit} isCompact={false} callback={callback} />
      <ShareButton unit={unit} withIcon={true} callback={callback} />
      <DeleteButton unit={unit} callback={callback} />
    </>
  );
};

export default ChannelActionList;
