import React, { useEffect, useState } from 'react';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';

import Input from '@shared/common/input';
import Button from '@shared/common/Button';
import Modal from '@shared/common/Modal';

import {
  useChangeWorkspaceMutation,
  useDeleteWorkspaceMutation,
} from '@app/redux/api/workspaceApi';
import { track } from '@amplitude/analytics-browser';
import useWorkspace from '@app/hooks/useWorkspace';
import useUser from '@app/hooks/useUser';

const generalSettingsSchema = object({
  workspace: string().min(1, 'Workspace is required'),
});

type GeneralSettingsInput = TypeOf<typeof generalSettingsSchema>;

const GeneralSettings = () => {
  const [showChangeWorkspace, setShowChangeWorkspace] = React.useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = React.useState(false);

  const [changeWorkspace, { isLoading }] = useChangeWorkspaceMutation();
  const [deleteWorkspace, { isLoading: isDelete }] =
    useDeleteWorkspaceMutation();

  const methods = useForm<GeneralSettingsInput>({
    resolver: zodResolver(generalSettingsSchema),
  });

  const watchWorkspace = methods.watch('workspace');

  const user = useUser();
  const { workspace } = useWorkspace();

  useEffect(() => {
    track('general_settings_opened');
  }, []);

  useEffect(() => {
    if (!workspace) {
      return;
    }
    methods.setValue('workspace', workspace.name || '');
  }, [workspace]);

  useEffect(() => {
    if (methods.formState.dirtyFields.workspace && !showChangeWorkspace) {
      setShowChangeWorkspace(true);
    }
  }, [watchWorkspace]);

  const onCancelChangeWorkspace = () => {
    if (!workspace) {
      return;
    }
    setShowChangeWorkspace(false);
    methods.resetField('workspace');
    methods.setValue('workspace', workspace.name || '');
  };

  const handleChangeWorkspaceName = async () => {
    if (!workspace) {
      return;
    }
    const valid = await methods.trigger('workspace');

    if (valid) {
      changeWorkspace({
        name: methods.getValues('workspace'),
        id: workspace.id || '',
      })
        .unwrap()
        .then(() => {
          track('general_settings_ws_name_changed');
          setShowChangeWorkspace(false);
        })
        .catch((error) => {
          track('general_settings_ws_name_changed_failed', {
            reason:
              error.data.message || 'An error occured. Please, try again.',
          });
          methods.setError('workspace', {
            type: 'Invalid credentials',
            message:
              error.data.message || 'An error occured. Please, try again.',
          });
        });
    }
  };

  const handleDeleteWorkspace = () => {
    if (!workspace) {
      return;
    }

    deleteWorkspace({ id: workspace.id || '' })
      .unwrap()
      .then(() => setDeleteIsOpen(false));
  };

  if (!workspace) return null;
  if (!user) return null;

  return (
    <FormProvider {...methods}>
      <Modal
        modalIsOpen={deleteIsOpen}
        closeModal={() => setDeleteIsOpen(false)}
        closeButton={false}
      >
        <div
          className='text-20-16 font-medium text-text90'
          contentEditable='false'
        >
          Delete workspace
        </div>
        <div className='w-96 mt-5 text-base text-text70'>
          This action cannot be undone. This will permanently delete your entire
          account. All private workspaces will be deleted, and you will be
          removed from all shared workspaces.
        </div>
        <div className='mt-4 font-medium text-16-28 text-errorText'>
          {workspace.name}
        </div>
        <div className='flex mt-10 items-center'>
          <Button
            label='Cancel'
            styleType='small-gray'
            onClick={() => setDeleteIsOpen(false)}
            className='w-full mr-3'
          />
          <Button
            label='Delete workspace'
            className='!text-errorText w-full'
            styleType='small-gray'
            onClick={handleDeleteWorkspace}
            isLoading={isDelete}
            disabled={isDelete}
          />
        </div>
      </Modal>
      <div className='text-2xl3 text-text90'>General</div>
      <div className='font-medium text-sm text-text50 mt-12.5'>
        Workspace name
      </div>
      <div className='mt-2 flex items-center'>
        <div className='w-65 mr-3'>
          <Input
            name='workspace'
            type='text'
            disabled={user.id !== workspace.owner.id}
          />
        </div>
        {showChangeWorkspace && (
          <>
            <Button
              label='Cancel'
              className='mr-2'
              styleType='small-gray'
              onClick={onCancelChangeWorkspace}
            />
            <Button
              label='Change'
              styleType='small-primary'
              disabled={isLoading}
              isLoading={isLoading}
              onClick={handleChangeWorkspaceName}
            />
          </>
        )}
      </div>
      <div className='mt-7.5 h-px bg-text10'></div>
      <div className='font-medium text-lg3 text-text90 mt-7.5'>
        Delete workspace
      </div>
      <div className='font-normal leading-[18px] text-sm text-text50 mt-4'>
        This action cannot be undone. This will permanently delete the
        workspace, including all
        <br />
        pages and files. Please type the name of the workspace to confirm.
      </div>
      <div className='mt-5 flex items-center'>
        {user.id !== workspace.owner.id && (
          <Button
            label='Leave workspace'
            className='mr-3'
            styleType='small-gray'
          />
        )}
        {user.id === workspace.owner.id && (
          <Button
            label='Delete workspace'
            className='!text-errorText'
            styleType='small-gray'
            onClick={() => setDeleteIsOpen(true)}
          />
        )}
      </div>
    </FormProvider>
  );
};

export default GeneralSettings;
