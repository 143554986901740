import { Workspace } from '@entities/models/workspace';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentUser,
  setWorkspace as setWorkspaceContext,
  setWorkspaces as setWorkspacesContext,
} from '../redux/features/userSlice';
import { useEffect, useState } from 'react';
import { getWorkspaces } from '../services/workspace.service';
import _ from 'lodash';

const useWorkspace = () => {
  const userState = useSelector(selectCurrentUser);
  const [workspace, setWorkspace] = useState<Workspace | null>(null);
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userState || !userState.isLoggedIn) {
      setWorkspace(null);
    } else {
      if (userState.workspaces.length == 0) {
        getWorkspaces().then((workspaces) => {
          dispatch(setWorkspacesContext(workspaces));
          setWorkspaces(workspaces);
        });
      } else {
        setWorkspaces(userState.workspaces);
      }
      if (!userState.workspace) {
        const workspaceId = localStorage.getItem('workspaceId');
        if (workspaceId) {
          const workspace = userState.workspaces.find(
            (workspace) => workspace.id === workspaceId
          );
          if (workspace) {
            dispatch(setWorkspaceContext(workspace));
            setWorkspace(workspace);
          } else {
            dispatch(setWorkspaceContext(userState.workspaces[0]));
            setWorkspace(userState.workspaces[0]);
          }
        } else {
          dispatch(setWorkspaceContext(userState.workspaces[0]));
          setWorkspace(userState.workspaces[0]);
        }
      } else {
        setWorkspace(userState.workspace);
      }
    }
  }, [userState]);

  const switchWorkspace = (workspace: Workspace): void => {
    const _workspace = userState.workspaces.find(
      (workspace) => workspace.id === workspace.id
    );
    if (_workspace) {
      dispatch(setWorkspaceContext(workspace));
      setWorkspace(workspace);
    } else {
      dispatch(setWorkspaceContext(userState.workspaces[0]));
      setWorkspace(userState.workspaces[0]);
    }
  };

  return { workspace, workspaces, switchWorkspace };
};

export default useWorkspace;
