import { IDuplicateButton } from './DuplicateButton.types';
import { track } from '@amplitude/analytics-browser';
import toast from 'react-hot-toast';
import { SuccessToast } from '@shared/common/Toast';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addUnit } from '@app/redux/features/unitsSlice';
import { duplicate } from '@app/services/unit.service';
import * as _ from 'lodash';

import { ReactComponent as Duplicate } from '@images/duplicate.svg';

const DuplicateButton = ({ unit, callback }: IDuplicateButton) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleDuplicate = async () => {
    duplicate(unit.id).then((item) => {
      dispatch(addUnit(item));
      track(`${unit.type}_duplicate_action`);
      toast.custom(
        <SuccessToast text={`Duplicate ${unit.type} ${unit.name} created`} />
      );
      _.delay(() => {
        navigate(`/workspace/${item.id}`);
      }, 100);
    });

    callback && callback();
  };

  return (
    <div className='action-item' onClick={handleDuplicate}>
      <Duplicate className='action-item__icon' />
      Duplicate
    </div>
  );
};

export default DuplicateButton;
