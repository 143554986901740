import { ReactNode, useMemo } from 'react';
import { useClickOutside } from '@app/hooks/useClickOutside';
import { ReactComponent as Dash } from '@images/dash.svg';
import cn from 'classnames';
import cssStyles from './style.module.scss';

export interface IMoreButton {
  position: Position;
  isCompact: boolean;
  children: ReactNode;
  onOpenCallback?: any;
}

export type Position = 'left' | 'bottom' | 'right' | 'top' | 'under';

const MoreButton = ({
  position,
  isCompact,
  children,
  onOpenCallback,
}: IMoreButton) => {
  const { ref, isVisible, setIsVisible } = useClickOutside(false);

  const handleOpenPopUp = (event: any) => {
    event.stopPropagation();
    onOpenCallback && onOpenCallback();
    setIsVisible(true);
  };

  const handleClosePopUp = (event: any) => {
    event.stopPropagation();
    setIsVisible(false);
  };

  const style = useMemo(() => {
    if (ref && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      if (position === 'bottom') {
        return {
          top: rect.bottom + 'px',
          right: window.innerWidth - rect.right + 'px',
        };
      }
      if (position === 'right') {
        return {
          top: rect.bottom,
          left: rect.left + 10 + 'px',
        };
      }
    }
    return {};
  }, [isVisible, position, ref]);

  return (
    <div
      ref={ref}
      className={cn(cssStyles.moreButton, {
        [cssStyles.moreButtonCompact]: isCompact,
      })}
      onClick={!isVisible ? handleOpenPopUp : handleClosePopUp}
    >
      <Dash />
      {isVisible && (
        <div className='actions-list' style={style}>
          {children}
        </div>
      )}
    </div>
  );
};

export default MoreButton;
