import Button from '@shared/common/Button';
import Modal from '@shared/common/Modal';
import { useState } from 'react';
import './style.css';
import { BASE_API } from '@app/constants/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { addUnit } from '@app/redux/features/unitsSlice';
import { IChannel } from '@entities/models/IChannel';
import customFetch from '@app/utils/customFetch';
import { track } from '@amplitude/analytics-browser';
import toast from 'react-hot-toast';
import { SuccessToast } from '@shared/common/Toast';
import { selectCurrentUser } from '@app/redux/features/userSlice';
import { useNavigate } from 'react-router-dom';
import { Unit } from '@entities/models/unit';

type AccessType = 'Open' | 'Private';

const CreateChannel = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [channelName, setChannelName] = useState<string>('');

  const [isLoading, setLoading] = useState<boolean>(false);

  const workspace = useSelector(selectCurrentUser).workspace;

  const generateColor = () => {
    const hue = Math.floor(Math.random() * 359);
    return `hsl(${hue},50%,75%)`;
  };

  const handleCreateChannel = async () => {
    setLoading(true);
    track('create_channel_action');
    if (workspace) {
      customFetch(`${BASE_API}/frontend/workspace/${workspace.id}/channel`, {
        method: 'POST',
        body: JSON.stringify({
          name: channelName,
          privacy: 'public',
          color: generateColor(),
        }),
      })
        .then((resp) => resp.json())
        .then((unit: IChannel) => {
          dispatch(
            addUnit({
              id: unit.id,
              name: unit.name,
              type: 'channel',
              parentUnit: null,
              color: unit.color,
            } as Unit)
          );
          setLoading(false);
          toast.custom(
            <SuccessToast
              text={`Channel '${channelName}' successfully created`}
            />
          );
          handleClose();
          setChannelName('');
          navigate(`workspace/${unit.id}`);
        })
        .catch((error) => {
          track('create_channel_action_failed', {
            reason: JSON.stringify(error),
          });
        });
    }
  };

  return (
    <Modal
      title='Create new channel'
      closeModal={() => handleClose()}
      modalIsOpen={isOpen}
      wrapChildren={true}
      userClassName='create-new-channel__modal'
    >
      <div className='create-new-channel'>
        <div className='create-new-channel__label'>Channel name</div>
        <div className='create-new-channel__row'>
          <input
            type='text'
            placeholder='New channel'
            className='create-new-channel__row__input'
            value={channelName}
            onChange={(event) => setChannelName(event.target.value)}
          />
        </div>
        <div className='create-new-channel__row__button-row'>
          <Button
            label='Create channel'
            styleType='small-primary'
            disabled={channelName.length < 3 || isLoading}
            onClick={handleCreateChannel}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateChannel;
