import { useEffect, useState } from 'react';
import useWorkspace from './useWorkspace';
import { Unit } from '@entities/models/unit';
import { getDeletedUnits, getUnits } from '../services/unit.service';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUnit as addUnitContext,
  selectUnits,
  setDeletedUnits,
  setUnits as setUnitsContext,
} from '@app/redux/features/unitsSlice';
import { getChannels } from '@app/services/channel.service';

const useUnits = () => {
  const { workspace } = useWorkspace();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (workspace) {
        const channels = await getChannels(workspace.id);
        let _units = (await getUnits(workspace.id)).map((_unit) => {
          const unit = new Unit(
            _unit.id,
            _unit.name,
            _unit.type,
            _unit.parentUnit,
            _unit.color,
            _unit.isDefault,
            false,
            _unit.createdAt
          );
          return unit;
        });
        const defaultChannel =
          channels.find((channel) => channel.byDefault) ||
          channels.find(
            (channel) => channel.name.toLowerCase() === 'private'
          ) ||
          channels[0] ||
          _units.find(
            (unit) =>
              unit.type === 'channel' && unit.name.toLowerCase() === 'private'
          ) ||
          _units.filter((unit) => unit.type === 'channel')[0];
        let deletedUnits = await getDeletedUnits(workspace.id);
        dispatch(setDeletedUnits(deletedUnits));

        if (defaultChannel) {
          const tempDefaultChannel = _units.find(
            (unit: Unit) => unit.id === defaultChannel.id
          );
          if (tempDefaultChannel) {
            tempDefaultChannel.isDefault = true;
          }
          _units.sort((unit: Unit) => (unit.isDefault ? 1 : -1));
        }
        _units.sort((a, b) => {
          let c = a.createdAt ? new Date(a.createdAt) : new Date();
          let d = b.createdAt ? new Date(b.createdAt) : new Date();
          return d.getTime() - c.getTime();
        });
        dispatch(setUnitsContext(_units));
      }
    };

    fetchData();
  }, [workspace]);

  const addUnit = (unit: Unit): void => {
    dispatch(addUnitContext(unit));
  };

  return {
    units: useSelector(selectUnits).units,
    addUnit,
    deletedUnits: useSelector(selectUnits).deletedItems,
  };
};

export default useUnits;
